import axios from 'axios'
const url = 'https://api.gpdf.com.br/apilanchonete/api/'
//const url = 'http://localhost:8093/apiopen/'


export const http = axios.create({
     baseURL: url, 
})

export default {
     url,
}